import React from "react";

//import data
import { about } from "../data";
//import nav scroll
import { Link } from 'react-scroll'

//import icons
import { IoIosArrowDroprightCircle } from "react-icons/io";


const About = () => {
  //destructure data
  const { title, subtitle1, subtitle2, icon, link } = about;

  return (
    <section
      name="nosotros"
      className=" py-[80px] md:py-[110px] lg:pt-[140px] lb:pb-[180px]"
    >
      <div
        className="container mx-auto px-[20px] lg:px-[135px]"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        {/*section title */}
        <div className="section-title-group justify-start">
      
          <h2 className="h2 section-title">
            {title} <span className="text-red-500">.</span>
          </h2>
        </div>

        {/* subtitle 1 */}
        <p
          className="md:text-body-md mb-12"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {subtitle1}
        </p>
        {/* subtitle 2 */}
        <p
          className="md:text-body-md mb-8"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          {subtitle2}
        </p>

        {/* link */}
        <div data-aos="fade-up" data-aos-delay="400">
          <Link
            className="link flex items-center gap-x-4 hover:gap-x-6 transition-all hover:text-red-500 hover:cursor-pointer"
            to={'disciplinas'} spy={true} smooth={true} offset={10}
            duration={500}
          >
            {link}
            <IoIosArrowDroprightCircle className="text-2xl" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default About;
