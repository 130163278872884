import React, {useState} from 'react';

// import icons
import {FaChevronCircleDown,FaChevronCircleUp} from 'react-icons/fa'

export const Accordion = ({accordion}) => {

  //accordion state
  const[isOpen,setIsOpen]=useState(false);

  //destructure accordion
  const{question,answer} = accordion
  return (
  <div onClick={()=>setIsOpen(!isOpen)} className='cursor-pointer'>
    <div className='bg-white border rounded-sm'>
      <div className='min-h-[60px] flex items-center justify-between px-[30px]
       '>
        <h6 className='h6'>{question}</h6>
        <div>
          {isOpen? (<FaChevronCircleUp className='text-[20px] text-neutral-500 '/>):( <FaChevronCircleDown className='text-[20px] text-neutral-500 ' />)}
        </div>
       </div>
       <div className={`${isOpen ? 'min-h-[200px] lg:min-h-[160px]  ': 'min-h-0 '} max-h-0 overflow-hidden flex justify-center transition-all px-[30px]`}  >
       <div className='mt-6' >{answer} </div>
       </div>
    </div>
    </div>
    )
};

 
