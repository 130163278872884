import React from 'react';

// import data
import { footer } from '../data';

const Footer = () => {
  // destructure footer data

  const {logo, copyrightText}= footer;

  return <footer className='bg-neutral-500 h-[125px] md:h-[150px] px-[20px] '>
    <div className='container mx-auto h-full flex justify-evenly items-center md:items-end md:pb-[50px] flex-col md:flex-row '
    data-aos='fade-up'
    dat-aos-delay='200'
    >
      {/* logo */} 
      <a href="/">
        <img className='h-[30px]  md:w-[100%]  md:h-[50px] ' src={logo} alt="" />
      </a>
      {/* copyright text */}
      <p className='text-neutral-300 text-sm  text-center' > {copyrightText} </p>
    </div>
  </footer>;
};

export default Footer;
