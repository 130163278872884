import React from "react";

//import data
import { workouts } from "../data";

//import swiér react cmponents
import { Swiper, SwiperSlide } from "swiper/react";

// import swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "../workoutSlider.css";

//import required modules
import { Navigation } from "swiper";

import { BsWhatsapp } from "react-icons/bs";
// desctructure workout data
const { programs } = workouts;

export const WorkoutSlider = () => {
  return (
    <Swiper
      slidesPerView={2}
      spaceBetween={32}
      navigation={true}
      breakpoints={{
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      }}
      modules={[Navigation]}
      className="workoutSlider"
    >
      {programs.map((program, index) => {
        // destructure program
        const { image, name, message,number } = program;
        return (
          <SwiperSlide
            className="max-w-[320px] max-h-[320px] relative"
            key={index}
          >
            <div className="relative  flex items-center justify-center   
            left-[75%] w-9 h-9 top-[3.5rem] 
            sm:left-[80%] sm:top-16 sm:w-12 sm:h-12
            md:left-[75%] md:top-16 md:w-12 md:h-12 

           bg-red-500   rounded-full   shadow-black shadow-md  hover:bg-red-600 transition ease-in-out duration-200 ">
              <a
                href={`https://wa.me/+54${number}/?text=${message}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <BsWhatsapp className="m-auto text-2xl  sm:text-3xl   text-neutral-500 " />
              </a>
            </div>
            <img className="w-full h-full object-cover" src={image} alt="" />
            <div className="absolute left-[20px] bottom-[20px] bg-white h-[26px] px-[14px] flex-items-center rounded-[1px]">
              <div className="font-primary font-semibold texts-sm text-neutral-500">
                {name}
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
