import React from 'react';
//import data
import {banner} from '../data'
//import nav scroll
import { Link } from 'react-scroll'


const Banner = () => {
//destructuro el los datos del baner
const {titlePart1,titlePart2,subtitle,textBtn} = banner;


  return (
  <section className='bg-neutral-500 h-[790px]'>
    
    <div className='container mx-auto h-full'>
      <div className='flex items-center h-full relative -space-x-48 lg:-space-x-24'>
        {/*text*/}
        <div className='text-white flex-1 z-10 pl-6 lg:pl-0'>
          <h1 className='h1 text-white mb-8' data-aos = 'fade-down' data-aos-delay='500'>{titlePart1} <br/> <span
          className='text-red-500'>{titlePart2}</span>
          </h1>
          <p className='max-w-[415px] text-body-md lg:text-body-lg mb-8' 
          data-aos = 'fade-down' data-aos-delay='600'>{subtitle}</p>
          <button className='btn btn-sm lg:btn-lg btn-secondary hover:text-red-500'
          data-aos = 'fade-down' data-aos-delay='700'>
             <Link  to={'nosotros'} spy={true} smooth={true} offset={10}
            duration={500}>
              {textBtn}
         </Link>
         </button>
        </div>
         {/*imagen */}
  <div className='bg-blue-300 wf-full h-full bg-banner2  sm:bg-banner bg-cover bg-right lg:bg-center bg-no-repeat flex-1'
  data-aos = 'fade-right' data-aos-delay='500'></div>
      </div>
 
    </div>
  
    </section>);
};

export default Banner;
